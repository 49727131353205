import Axios, { AxiosInstance } from "axios";
import AuthService from "@/core/services/AuthService";

import router from "@/router";
import { App } from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
import HTTPService from "@/core/services/HTTPService";
import JwtService from "@/core/services/JwtService";

class ApiService {
  public static vueInstance: App;
  private static _httpRequest: AxiosInstance = Axios.create(
    ApiService.getConfig()
  );
  public static accessTokenKey = "token";

  public static async init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);

    ApiService.createRequestHandler();
    ApiService.events();

    const r = router.currentRoute.value;

    const needAuth =
      typeof r.meta.auth !== "undefined" ? !(r.meta.auth === false) : true;

    if (needAuth) {
      await AuthService.me();
    }
  }

  public static get httpRequest(): AxiosInstance {
    return ApiService._httpRequest;
  }

  public static set httpRequest(value: AxiosInstance) {
    ApiService._httpRequest = value;
  }

  public static createRequestHandler() {
    ApiService._httpRequest = Axios.create(ApiService.getConfig());
  }

  public static getConfig() {
    const token = localStorage.getItem(ApiService.accessTokenKey);
    let config;

    if (token) {
      ApiService.config.headers.Authorization = `Bearer ${token}`;
      config = ApiService.config;
    } else {
      config = ApiService.configWithoutAuth;
    }

    return config;
  }

  public static events() {
    ApiService._httpRequest.interceptors.response.use(
      (res) => {
        return res;
      },
      (err) => {
        // Exception
        if (
          typeof err.message !== "undefined" &&
          err.message.length > 0 &&
          typeof err.response === "undefined"
        ) {
          router.push("/500").then(() => {
            console.error(err);
          });
        } else if (err.response.status === 401) {
          if (err.response.data.message === "Token has expired") {
            return Axios.post(
              process.env.VUE_APP_API_URL + "refresh",
              {},
              {
                headers: {
                  Authorization:
                    "Bearer " + localStorage.getItem(ApiService.accessTokenKey),
                },
              }
            )
              .then((res) => {
                JwtService.saveToken(res.data.access_token);

                ApiService.createRequestHandler();
                const header = err.config.headers;
                header.Authorization = "Bearer " + res.data.access_token;

                err.config.headers = header;

                // double request
                return ApiService._httpRequest.request(err.config);
              })
              .catch((err) => {
                if (err.response.status === 405) {
                  if (err.response.data.data.type === "expired") {
                    // TODO Заменить на нормальную либу
                    /*
                    Swal.fire({
                      text: "Ошибка авторизации!",
                      icon: "error",
                      buttonsStyling: false,
                      confirmButtonText: "ОК",
                      customClass: {
                        confirmButton: "btn fw-bold btn-light-primary",
                      },
                    });*/

                    AuthService.removeToken();
                    router.push("/login").then(() => {
                      console.info("redirect to login");
                    });
                  }
                } else {
                  AuthService.removeToken();
                  router.push("/login").then(() => {
                    console.info("redirect to login");
                  });
                }

                return null;
              });
          } else if (
            err.response.data.message ===
            "Token has expired and can no longer be refreshed"
          ) {
            router.push("/login").then((r) => {
              console.warn("Error redirect: " + r);
            });
          } else {
            ApiService.unsetToken();
            if (router.currentRoute.value.path !== "/login") {
              router.push("/login").then((r) => {
                console.warn("Error redirect: " + r);
              });
            }
          }
        } else if (err.response.status === 412 || err.response.status === 428) {
          // TODO Заменить на нормальную либу
          /*
Swal.fire({
text: "Ошибка запроса, обратитесь в службу поддержки. Код: 1006",
icon: "error",
buttonsStyling: false,
confirmButtonText: "ОК",
customClass: {
confirmButton: "btn fw-bold btn-light-primary",
},
});*/
        } else if (err.response.status === 413) {
          // TODO Заменить на нормальную либу
          /*
Swal.fire({
  text: "Ошибка запроса, обратитесь в службу поддержки. Код: 1005",
  icon: "error",
  buttonsStyling: false,
  confirmButtonText: "ОК",
  customClass: {
    confirmButton: "btn fw-bold btn-light-primary",
  },
});
*/
        } else if (err.response.status === 422 || err.response.status === 429) {
          err.data = err.response.data;
          return err;
        } else if (err.response.status === 404) {
          // redirect to 500 page
          router.push("/404").then(() => {
            console.warn("404 Server error");
          });
        } else if (err.response.status === 500) {
          const elements = document.getElementsByClassName("modal-backdrop");

          if (elements.length > 0) {
            for (let i = 0; i < elements.length; i++) {
              (elements[i] as HTMLElement).style.display = "none";
            }
          }

          // redirect to 500 page
          router.push("/500").then(() => {
            console.warn("500 Server error");
          });
        } else {
          console.log("TEST");
          if (typeof err.response.data !== "undefined") {
            err.data = err.response.data;
            return null;
          }
        }
      }
    );
  }

  public static unsetToken() {
    localStorage.removeItem(ApiService.accessTokenKey);
    localStorage.removeItem("email");
  }

  public static config = {
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "",
    },
  };

  public static configWithoutAuth = {
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
  };
}

export default ApiService;
